import { default as _91_46_46_46slug_93Z2gjnVOwUKMeta } from "/var/builds/macopediapwafront/production/releases/132921/macopedia-shared/pages/[...slug].vue?macro=true";
import { default as T3PagenApJKpmyvpMeta } from "/var/builds/macopediapwafront/production/releases/132921/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.29/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/var/builds/macopediapwafront/production/releases/132921/macopedia-shared/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/var/builds/macopediapwafront/production/releases/132921/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.29/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue").then(m => m.default || m)
  }
]